import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import { formatCurrency } from 'utils/NumberUtils';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { BsFacebook } from 'react-icons/bs';
import { imageBuilder } from 'sanity/client';

export default function ProductDetailCard( { code, content, price, images: productImages } ) {
  const images = ( productImages || [] ).map( ( image ) => {
    try {
      return imageBuilder.image( image ).width( 1280 ).quality( 0 ).url();
    } catch ( e ) {
      console.error( 'image upload fail', code );
      return null;
    }
  } ).filter( ( _value ) => _value != null );

  const name = `FO4 #${ code }`;
  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-lg font-bold text-gray-700">
        {name}
      </h1>
      <div>
        <span className="text-base font-bold text-gray-600">Thông tin:</span>
        <p className="text-base text-gray-600">
          {content}
        </p>
        { price > 0 && (
          <div className="mt-2">
            <BanknotesIcon className="w-6 h-6 inline-block text-emerald-500 mr-1" />
            <span className="text-lg font-bold text-gray-900">
              {formatCurrency( price )}
            </span>
          </div>
        ) }
        <span className="mt-2 text-base font-bold text-gray-600">Liên hệ:</span>
        <div className="flex gap-4">
          <Link href="https://zalo.me/0792268936" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <Image
                placeholder="blur"
                blurDataURL="/images/placeholder.png"
                src="/zalo.svg"
                alt="zalo 0792268936"
                width={ 24 }
                height={ 24 }
                unoptimized
              />
              <span>0792268936</span>
            </a>
          </Link>
          <Link href="https://www.facebook.com/dinhan.davidbi" passHref>
            <a className="flex gap-2 items-start" target="_blank" rel="noreferrer">
              <BsFacebook className="w-6 h-6 text-blue-600" />
              <span>Phước Chelsea</span>
            </a>
          </Link>
        </div>
      </div>
      <h1 className="text-base font-bold text-gray-600">Chi tiết:</h1>
      { images.map( ( image ) => (
        <div key={ image } className="unset-img relative shadow-lg">
          <Image
            layout="fill"
            src={ image }
            className="custom-img rounded"
            placeholder="blur"
            blurDataURL="images/placeholder.png"
            alt={ name }
            unoptimized
          />
        </div>
      ) ) }
    </div>
  );
}

ProductDetailCard.propTypes = {
  _id: PropTypes.string,
  code: PropTypes.string,
  content: PropTypes.string,
  price: PropTypes.number,
  status: PropTypes.bool,
  images: PropTypes.array,
  _createdAt: PropTypes.string,
};
