import React, { useContext, useState } from 'react';
import Image from 'next/image';
import Head from 'next/head';
import client from 'sanity/client';
import classNames from 'classnames';
import { fetchYoutube } from 'pages/api/youtube';
import ModalContext from 'context/ModalContext';
import ProductCard from 'components/ProductCard';
import ProductDetailCard from 'components/ProductDetailCard';
import YoutubeSection from '@youtube';
import bannerImg from 'public/images/fo4-banner.png';
import bgVideoImg from 'public/images/fo4-bg-videos.png';

export default function HomePage( { categories, accounts, soldAccounts, youtube } ) {
  const { openModal } = useContext( ModalContext );
  const [ selectedCategory, setSelectedCategory ] = useState( [] );
  const [ loadSoldOut, setLoadSoldOut ] = useState( 10 );

  const openProductModal = ( id ) => {
    const account = accounts.find( ( acc ) => acc._id === id );
    account && openModal( <ProductDetailCard { ...account } /> );
  };

  const loadMoreSoldOut = () => {
    setLoadSoldOut( ( current ) => {
      const next = current + 10;
      return next > soldAccounts.length ? soldAccounts.length : next;
    } );
  };

  const handleClickCategory = ( category ) => {
    setSelectedCategory( ( prev ) => ( prev.includes( category._id ) ? prev.filter( ( item ) => item !== category._id ) : [ ...prev, category._id ] ) );
  };

  const availableSectionTitle = `Đang bán (${ accounts.length })`;
  const soldOutSectionTitle = `Đã bán (${ soldAccounts.length })`;

  return (
    <>
      <Head>
        <title>Nghiện Fifa - Shop Acc FO4, Bơm bp, Mua Bán Acc FO4, Fifa Online 4</title>
      </Head>
      <section>
        <div className="unset-img relative">
          <Image
            src={ bannerImg }
            layout="fill"
            objectFit="cover"
            className="custom-img"
            placeholder="blur"
            alt="banner"
          />
        </div>
      </section>

      <section>
        <div className="pointer-events-none relative">
          <Image
            className="f04-bg-videos"
            src={ bgVideoImg }
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="bg-videos"
            placeholder="blur"
            style={ { fontSize: 12 } }
          />
        </div>
        <div className="container mx-auto max-w-7xl py-12" style={ { zIndex: 1 } }>
          <YoutubeSection data={ youtube } />
        </div>
      </section>

      <hr className="container mx-auto max-w-xl" />

      <section className="container mx-auto max-w-7xl my-8">
        <h2 className="text-2xl font-bold mt-8">{ availableSectionTitle }</h2>
        <div className="flex flex-wrap gap-4 mt-4 mb-8">
          {categories.map( ( category ) => (
            <button
              type="button"
              key={ category._id }
              className={ classNames( 'px-8 py-2 rounded border border-teal-700', selectedCategory.includes( category._id ) && 'text-white bg-teal-700' ) }
              onClick={ () => handleClickCategory( category ) }
            >
              {category.name}
            </button>
          ) )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-y-10 gap-x-6 xl:gap-x-8">
          {
            accounts && accounts.filter( ( acc ) => !selectedCategory.length || selectedCategory.includes( acc?.category?._ref ) ).map( ( acc ) => (
              <ProductCard
                key={ acc._id }
                { ...acc }
                optimizeImage
                onClick={ () => openProductModal( acc._id ) }
              />
            ) )
          }
        </div>
      </section>

      <hr className="container mx-auto max-w-xl" />

      <section className="container mx-auto max-w-7xl">
        <h2 className="text-2xl font-bold my-8">{ soldOutSectionTitle }</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-y-10 gap-x-6 xl:gap-x-8">
          {
            soldAccounts && soldAccounts.slice( 0, loadSoldOut ).map( ( acc ) => (
              <ProductCard
                key={ acc._id }
                { ...acc }
                onClick={ () => openProductModal( acc._id ) }
              />
            ) )
          }
        </div>
        <div className="mt-6 text-center">
          <button
            type="button"
            className="bg-emerald-500 hover:bg-emerald-700 text-white font-bold py-2 px-4 rounded"
            onClick={ loadMoreSoldOut }
          >
            Xem Thêm
          </button>
        </div>
      </section>
    </>
  );
}

export async function getServerSideProps( { res } ) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=299',
  );

  const youtube = await fetchYoutube();
  const accounts = await client.fetch( '*[_type == "FO4Account" && status == true] | order(_createdAt desc)' );
  const categories = await client.fetch( '*[_type == "AccountCategory"]' );
  const soldAccounts = await client.fetch( '*[_type == "FO4Account" && status == false]' );
  return {
    props: {
      categories,
      accounts,
      soldAccounts,
      youtube,
    },
  };
}
