import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { formatCurrency } from 'utils/NumberUtils';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { imageBuilder } from 'sanity/client';

const defaultThumbnail = ( code ) => `https://via.placeholder.com/300x168.png?text=Account+FO4+${ code }`;

export default function ProductCard( { code, images, content, price, onClick, optimizeImage } ) {
  const name = `FO4 #${ code }`;
  let thumbnail;
  try {
    thumbnail = ( images?.length && imageBuilder.image( images[0] )?.width( 300 )?.quality( 10 )?.url() ) || defaultThumbnail( code );
  } catch ( e ) {
    console.error( 'image upload fail', code );
    thumbnail = defaultThumbnail( code );
  }

  return (
    <div className="group relative shadow-md rounded bg-gray-50 transition transform lg:hover:scale-110">
      <div
        className="relative cursor-pointer unset-img group-hover:opacity-75"
        role="presentation"
        onClick={ onClick }
      >
        <Image
          placeholder="blur"
          blurDataURL="images/placeholder.png"
          src={ thumbnail }
          alt={ name }
          className="custom-img rounded-tl rounded-tr"
          layout="fill"
          unoptimized={ !optimizeImage }
        />
      </div>
      <div className="p-2">
        <h3
          role="presentation"
          className="text-base text-center font-bold text-gray-700 cursor-pointer line-clamp-1"
          title={ name }
          onClick={ onClick }
        >
          {name}
        </h3>
        <p className="mt-2 text-sm text-gray-500 line-clamp-3" title={ content }>
          {content}
        </p>
        <p className="mt-4 text-base font-medium text-gray-900 text-right">
          <BanknotesIcon className="w-6 h-6 inline-block text-emerald-500 mr-1" />
          <span>{formatCurrency( price )}</span>
        </p>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  _id: PropTypes.string,
  code: PropTypes.string,
  content: PropTypes.string,
  price: PropTypes.number,
  status: PropTypes.bool,
  images: PropTypes.array,
  _createdAt: PropTypes.string,
  onClick: PropTypes.func,
  optimizeImage: PropTypes.bool,
};

ProductCard.defaultProps = {
  onClick: () => {},
  optimizeImage: false,
};
