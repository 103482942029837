import { createClient } from 'next-sanity';
import imageUrlBuilder from '@sanity/image-url';

const client = createClient(
  {
    projectId: 'h1q0vc96',
    dataset: 'production',
    apiVersion: '2022-05-28',
    useCdn: true,
  },
);

export const imageBuilder = imageUrlBuilder( client );

export default client;
