export const formatCurrency = ( price ) => ( typeof price === 'number'
  ? price.toLocaleString( 'vi-VN', { style: 'currency', currency: 'VND' } )
  : price );

export const formatNumber = ( value ) => {
  try {
    const number = Number( value );
    return number.toLocaleString( 'vi-VN' );
  } catch ( _ ) {
    return value;
  }
};
